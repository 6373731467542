import './NewsPage.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainApi from '../../../utils/MainApi';
import MarkdownSpan from "../../MarkdownSpan/MarkdownSpan";

export default function NewsPage({ data }) {
    const [displayPost, setDisplayPost] = useState(data);

    const { postId } = useParams();
    const mainApi = new MainApi();

    useEffect(() => {
        if (!displayPost?.title) {
            // refetch
            mainApi.getPost(postId)
                .then(setDisplayPost)
        }
    }, []);
    console.log(displayPost);

    return (
        <div className='newsPage'>
                <div className='newsPageHead'>
                    <div className='newsPageDescription'>
                        <h1>{displayPost.title}</h1>
                        <p><MarkdownSpan markdownText={displayPost.description ? displayPost.description : ' '} /></p>
                    </div>
                    <img src={displayPost.previewImage} alt={displayPost.title} className='newsPageImg'></img>
                </div>
                <hr className='newsPageHr'></hr>
                <p><MarkdownSpan markdownText={displayPost.content ? displayPost.content : ' '} /></p>
        </div>
    )
}
