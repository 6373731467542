import "./Technologies.css"
import a1 from "../../images/firms_cont_by_country.png"
import a2 from "../../images/perc_by_countries_more.png"

export default function Technologies() {
    return (
        <section id="Technologies">
            <div className="technologies">
                <h1>Процесс ротоформования</h1>
                <br />
                <br />
                <p>Процесс ротационного формования относительно прост и делится на четыре этапа:</p>
                <br />
                <strong>Загрузка материала в форму</strong>
                <br />
                <p>Чаще всего используется порошкообразный полимерный материал.
                    Ротационная форма представляет собой полую раковинообразную конструкцию,
                    состоящую из двух половин, реже используются формы состоящие из 3–х и более частей.</p>
                <br />
                <br />
                <strong>Формование изделия</strong>
                <br />
                <p> Форму закрывают и помещают в камеру нагрева, в которой производится нагрев и вращение формы.
                    При вращении полимер подплавляется и налипает на стенки формы. Вращение формы в
                    тепловом поле производят со скоростью от 4 до 20 об/мин. Это значительно ниже,
                    чем при центробежном формовании полимеров. Формование заканчивают, когда весь
                    полимер расплавится и налипнет на стенки формы. Процесс ротационного формования
                    происходит при атмосферном давлении, причем при вращении формы масса материала
                    не оказывает существенное давление на ее стенку, поэтому ротационные формы могут
                    иметь очень тонкие стенки, и они относительно дешевы. Чаще всего литьевые формы
                    изготавливают из стали или алюминия. Объем получаемых изделий на оборудовании
                    ротационного формования определяется объемом камеры нагрева. Ротационным
                    формованием возможно получение очень крупных и объемных изделий. Одним из преимуществ
                    ротационного формования является возможность варьирования толщиной стенки изделий
                    простым изменением количества загружаемого материала в форму. Ротационным
                    формованием можно получать изделия с толщиной стенки до 20 мм.</p>
                <br />
                <br />
                <strong>Охлаждение формы с изделием</strong>
                <br />
                <p> Форму охлаждают потоком холодного воздуха или распыленной водой.
                    При этом форма продолжает вращаться для обеспечения равномерности затвердевания
                    полимера по объему изделия. Когда полимер окончательно затвердеет, вращение прекращают.</p>
                <br />
                <br />
                <strong>Извлечение изделия из формы </strong>
                <br />
                <p> Форму раскрывают и готовое изделие извлекают из формы. Ротационное формование
                    относится к безотходным процессам. При помощи специальных технологических
                    приемов и приемов при конструировании ротационных форм можно достичь 100%
                    выхода изделий из исходного сырья. Бракованные изделия возможно
                    утилизировать, а полученные полимерные материалы использовать для изготовления новых изделий.</p>
                <br />
                <br />
                <h1>Оборудование для ротационного формования</h1>
                <br />
                <br />
                <p>Формы крепятся на так называемой «руке». «Рука» производит вращение формы и
                    перемещает ее последовательно из зон загрузки/выгрузки изделия в камеру
                    нагрева и охлаждения. В простых машинах камеры нагрева и охлаждения
                    совмещены и используется одна «рука». Для увеличения производительности,
                    и если это позволяет объем камеры нагрева/охлаждения, на «руке» можно
                    укрепить несколько одинаковых или разных форм. Чаще всего используются
                    машины карусельного типа с тремя или четырьмя «руками». Это позволяет
                    увеличить производительность, сэкономить расход тепла и получать
                    несколько разных изделий одновременно. Каждая «рука» находится в определенной
                    зоне технологического цикла. Затем производится одновременное перемещение
                    «рук» в последующую зону получения изделий. В данном случае все изделия
                    на разных «руках» получают по единой технологической программе. Причем
                    время нахождения «руки» в определенной зоне определяется временем формования
                    самого трудоемкого изделия. В настоящее время разработаны машины с
                    независимыми «руками», позволяющими вести формование каждого из изделий
                    по собственной программе.</p>
                <br />
                <br />
                <h1>Преимущества метода</h1>
                <br />
                <br />
                <p>Преимущества ротационного формования можно проиллюстрировать примерами
                    исполнения и применения готовых изделий. Изделия, получаемые ротационным
                    формованием, находят широкое применение практически во всех сферах рынка.
                    Ротационным формованием можно получать многослойные изделия, каждый слой
                    из которых выполнен из разных полимерных материалов; изделия с двойной
                    стенкой; вспененные изделия. При ротационном формовании возможно
                    заформовывать в изделия различные детали и высококачественные графические
                    изображения. В отличие от методов выдувного или термоформования, методом
                    ротационного формование можно получать изделия очень сложной формы с
                    равнотолщинными стенками. Это достигается путем изменения соотношение
                    скоростей вращения литьевой формы по осям вращения. Как показано на
                    диаграмме, ротационное формование относится к высокопроизводительным
                    процессам, уступая по производительности лишь литью под давлением,
                    выдувному и экструзионному формованию. Причем, в ряде случаев экономически
                    целесообразно использование ротационного формования для получения партий
                    изделий, насчитывающих всего нескольких штук.</p>
                <br />
                <br />
                <strong>Таким образом, преимуществами процесса ротационного формования являются:</strong>
                <ul>
                    <li>простота процесса</li>
                    <li>высокая производительность</li>
                    <li>относительно невысокая стоимость литьевых форм и оборудования</li>
                    <li>возможность быстрого изготовления и ремонта литьевых форм</li>
                    <li>безотходность производства</li>
                    <li>возможность получения изделий без внутренних напряжений и без ориентации полимера</li>
                    <li>возможность изготовления монолитных изделий сложной формы</li>
                    <li>возможность изготовления крупногабаритных изделий</li>
                    <li>возможность многослойного формования и изготовления вспененных изделий и изделий с двойной стенкой</li>
                    <li>возможность заформовывания металлических деталей и высококачественных графических изображений</li>
                    <li>возможность изготовления одного и того же изделия с различной толщиной стенки без модификации литьевой формы</li>
                    <li>возможность одновременного изготовления разных изделий</li>
                </ul>
                <br />
                <br />
                <h1>Фирмы-производители</h1>
                <br />
                <br />
                <p>
                    Большое количество фирм, разбросанных в различных уголках мира, занимаются
                    ротационным формованием. Это объясняется тем, что выгодно размещать
                    оборудование как можно ближе к рынкам потребления. В данном случае
                    минимизируются затраты на транспортные расходы по доставке объемных
                    крупногабаритных изделий до потребителя. Наибольшее количество фирм
                    занимающихся ротационным формованием расположено в Северной Америке (США),
                    по данным ARM, на 1996 г более 400 фирм занималось выпуском изделий
                    ротационным формованием. В Европе – 255 фирм.
                    <br />
                    <br />
                    <img class="graph" src={a1} alt="" width="720" height="483" />
                    <br />
                    <br />
                </p>
                <p>
                    Лидирующее место в странах Европы занимает Великобритания (33% рынка) и
                    Франция (16% рынка). В странах Азии наиболее развито ротационное
                    формование в Индии и Японии (данные по Китаю отсутствуют).
                    В Индии в 1996 г насчитывалось 40 фирм и 130 единиц оборудования,
                    в Японии соответственно 11 и 115.
                    <br />
                    <br />
                    <img class="graph" src={a2} alt="" width="720" height="483" />
                    <br />
                    <br />
                </p>
                <p>
                    В США распределение товаров по группам очень неравномерно.
                    42% товаров выпускаемых ротационным формованием приходится на
                    игрушки, 20% на баки и емкости. В Европе сформировался более
                    равномерный рынок. Большую часть рынка Европы –27% составляют
                    изделия для сельского хозяйства и пищевой промышленности, 17%–изделия
                    промышленного назначения; 15%– изделия для автомобилей.
                    Если рассматривать более детально рынок Европы по конкретным
                    товарам, то на нем доминируют изделия для подземного использования
                    и трубы. Баки, емкости, емкости для канализации на втором месте.
                    Очень много выпускается игрушек, игровых комплексов и изделий для
                    сельского хозяйства и ферм. Немаловажную роль составляет рынок
                    деталей для автомобилей, баков для накопления воды, нефтепродуктов,
                    химикалий, контейнеров для мусора. Россия находится только на
                    стадии формирования рынка, возможности ротационного формования,
                    потребительские свойства изделий получаемых ротационным формованием
                    малоизвестны.
                </p>
                <br />
                <br />
                <h1>Заключение</h1>
                <br />
                <br />
                <p>
                    Ротационное формование является универсальным способом переработки
                    полимерных материалов, позволяющим производить высококачественную
                    конкурентоспособную товарную продукция для различных сфер применения.
                    Промышленность ротационного формования находится в стадии роста.
                    Россия находится только на стадии формирования рынка, возможности
                    ротационного формования, потребительские свойства изделий получаемых
                    ротационным формованием малоизвестны. Отсутствие отечественной
                    сырьевой базы является одной из основных причин, тормозящих развитие
                    процесса ротационного формования в России.
                </p>
                <p>&nbsp;</p>
                <div class="author "><em>По материалам статьи «Ротационное формование изделий из полимерных материалов», «Полимерные материалы» №11–12 2001г, «Анион»</em></div>
                <p>&nbsp;</p>
                <h3>Подробнее о техпроцессе на <a href="http://ekopromplast.ru/">ekopromplast.ru</a></h3>
            </div>
        </section>
    )
}